<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? m.title + ` №${data.code_doc}` : "Новый документ '" + m.title + "'" }}
        [Импорт]
        <br />
        {{ config.name }}
      </template>
      <a-loader v-if="loading" />
      <v-row v-else class="mt-6">
        <v-col v-for="(el, i) in modelForm2" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name])"
              :errors="errors"
              :readonly="data.status == 2"
              :config="{ dense: true, readonly }"
              @fieldData="onFieldData($event)"
              @validate="validate($event)"
              @changeValidator="fillFormValidators($event, false)"
            />
          </div>
        </v-col>
      </v-row>

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-delete v-if="id && isDelete" @click="removeDialogShow = true" />
        <a-btn-save v-if="isEdit" @click="submit()" :color="isChanged ? 'green' : 'primary'" />

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
    <su-Data ref="suEdit" v-model="suEditShow" :api="api" :id="id" :activate="value" :dataEdit="data" @show="suEditShow = true" v-if="getAccess('suData')" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";
import libsCashAccess from "./../../libsCashAccess";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel, libsCashAccess],
  props: {
    value: Boolean,
    id: Number,
    document: {
      type: Object,
      default: () => {
        return {};
      },
    },

    config: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      m: this.config.m,
      api: this.config.m.api,
      apiStatus: this.config.m.api,
      mForm: "date_doc#6,import_id#6,operation_type,import_data",
      removeDialogShow: false,
      showSelectDialog: false,
      suEditShow: false,
      loaded: false,
      baseId: null,
    };
  },
  computed: {
    operationList() {
      let res = JSON.parse(JSON.stringify(this.$root.dirs.operationTable));
      //return res;
      //операции описанные в ПКО или РКО
      const cp = (this.config?.m?.config || {}).map((e) => {
        return e.id;
      });
      res = res.filter((e) => {
        return cp.includes(e.id);
      });
      const d = this.data?.import_data?.["СекцияДокумент"] || null;
      if (d) {
        const l = this.$store.getters["config/get"].config.settings.importCashOrder.operations?.[d];
        if (l) {
          res.forEach((r) => {
            r.isActive = l.includes(r.id);
          });
          //console.log("ddddddddddddddddddddddddd", o, res);
        }
      }
      return res;
    },
    model() {
      let model = [
        { name: "date_doc", title: "Дата документа", type: "datetimestring", readonly: true },
        { name: "operation_type", title: "Операция", type: "select", dir1: "operationTable", options: this.operationList, validator: ["req"], autocomplete: true },
        { name: "import_id", title: "Номер импорта", type: "string", readonly: true },
        { name: "import_data", title: "Данные импорта", type: "object", readonly: true },
      ];
      return model;
    },
    modelForm2() {
      let mForm = [];
      const fieldsForm = this.mForm.split(",");
      fieldsForm.forEach((field) => {
        let f = field.split("#");
        mForm.push({ name: f[0], size: f[1] || 12 });
      });
      return mForm;
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
    if (this.id) {
      this.afterFetchData();
    } else {
      this.afterFetchData();
    }
  },
  watch: {},
  methods: {
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      this.isChanged = false;
      this.id = data.id;
      this.$emit("input");
      this.$emit("selectType", { type: data.operation_type, id: data.id });

      return;
    },
    afterFetchData(r) {
      //this.model = this.calcModel();
      if (this.id) {
      }
      this.loaded = true;
    },
  },
};
</script>
